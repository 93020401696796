// utils/handle403Error.js
import { toast } from 'react-toastify';

export const handle403Error = (error, navigate) => {
    if (error.response && error.response.status === 403) {
        const notificationShown = localStorage.getItem('notificationShown');

        if (!notificationShown) {
            localStorage.clear();
            localStorage.setItem('notificationShown', 'true');
            navigate('/login', { state: { errorMessage: 'Your session has expired. Please log in again.', notificationShown: false } });
        }
    } else {
        const errorMessage = error.response?.data?.error || 'An unexpected error occurred';
        console.error('Error fetching data:', error);
        toast.error(errorMessage);
    }
};
