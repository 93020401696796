import {
    Button,
} from '@mui/material';

import { styled } from '@mui/system';

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handle403Error } from '../utils/handle403Error'; // Import the shared function

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000,
});

const CustomButton = styled(Button)(({ theme }) => ({
    flex: 1,
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    marginBottom: '10px',
    '&:hover': {
        backgroundColor: '#115293',
    },
    [theme.breakpoints.down(650)]: {
        margin: '0px',
        padding: '8px 16px',
        minWidth: 'auto',
        width: '100%',
        fontSize: '12px',
    },
}));

const RedeemForm = ({ onClose, userId, playerName, totalBalance }) => {
    const [amount, setAmount] = useState('');
    const [amountError, setAmountError] = useState('');
    const [loading, setLoading] = useState(false); // Loading state to prevent multiple clicks
    const navigate = useNavigate();

    const role = localStorage.getItem("role");

    const getBalanceLabel = () => {
        switch (role) {
            case "STORE":
                return "Current Player Balance";
            case "SUB_DISTRIBUTOR":
                return "Current Store Balance";
            case "DISTRIBUTOR":
                return "Current Sub-Dist Balance";
            case "MASTER":
                return "Current Distributor Balance";
            default:
                return "";
        }
    };

    const getUserName = () => {
        switch (role) {
            case "STORE":
                return "Player Name";
            case "SUB_DISTRIBUTOR":
                return "Store Name";
            case "DISTRIBUTOR":
                return "Sub-Dist Name";
            case "MASTER":
                return "Distributor Name";
            default:
                return "";
        }
    };

    const getUserId = () => {
        switch (role) {
            case "STORE":
                return "Player Id";
            case "SUB_DISTRIBUTOR":
                return "Store Id";
            case "DISTRIBUTOR":
                return "Sub-Dist Id";
            case "MASTER":
                return "Distributor Id";
            default:
                return "";
        }
    };

    const handleSuccess = (message) => {
        toast.success(message || 'Redeem successful!', { autoClose: 3000 });
        onClose();
    };

    const getAuthParams = () => {
        const apiKey = localStorage.getItem('apiKey');
        if (!apiKey) {
            throw new Error('API key is missing');
        }
        return {
            'api-key': apiKey,
        };
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (loading) return; // Prevent multiple clicks
        setLoading(true); // Set loading to true to disable the button

        if (!amount) {
            setAmountError('Enter a redeem amount');
            setLoading(false); // Reset loading state on error
            return;
        } else if (isNaN(parseFloat(amount))) {
            setAmountError('Amount must be a number');
            setLoading(false); // Reset loading state on error
            return;
        } else if (parseFloat(amount) <= 0) {
            setAmountError('Amount must be greater than zero');
            setLoading(false); // Reset loading state on error
            return;
        }

        const payload = {
            username: playerName,
            balance: parseFloat(amount),
        };

        try {
            const params = getAuthParams();

            const response = await api.post('/v1/admin/transact/redeem', payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
                params,
            });

            const { success, message, error } = response.data;

            if (success) {
                handleSuccess(message); // Show success toast and close the form
            } else {
                handle403Error({ response: { data: { error } } }, navigate); // Show error toast
            }
        } catch (error) {
            console.error('Error:', error);
            handle403Error(error, navigate); // Show error toast
        } finally {
            setLoading(false); // Reset loading state after API call completes
        }
    };

    const styles = {
        form: {
            width: '300px',
            padding: '20px',
            background: 'white',
            borderRadius: '10px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: 2,
        },
        header: {
            marginBottom: '20px',
            textAlign: 'center',
        },
        inputGroup: {
            marginBottom: '15px',
        },
        label: {
            display: 'block',
            marginBottom: '5px',
            fontWeight: 'bold',
            fontSize: '14px',
        },
        input: {
            width: '100%',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ced4da',
        },
        errorText: {
            color: 'red',
            fontSize: '12px',
        },
        buttonGroup: {
            display: 'flex',
            flexDirection: 'column',
        },
        button: {
            flex: 1,
            padding: '10px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
            marginBottom: '10px',
        },
        submitButton: {
            backgroundColor: '#007bff',
            color: 'white',
            marginRight: '0',
        },
        cancelButton: {
            backgroundColor: '#f44336',
            color: 'white',
            border: '1px solid #ced4da',
        },
        buttonGroupRow: {
            flexDirection: 'row',
        },
        buttonMarginRight: {
            marginRight: '10px',
        },
    };

    const isDesktop = window.innerWidth >= 600;

    const handleAmountChange = (e) => {
        let value = e.target.value;
    
        // Allow only numbers and a single dot, and limit to two decimal places
        if (/^\d*\.?\d{0,2}$/.test(value)) {
            setAmount(value);
        }
    
        // Remove any error message if the value is valid and non-empty
        if (amountError && value) {
            setAmountError('');
        }
    };
    

    return (
        <div style={styles.form}>
            <h2 style={styles.header}>Redeem Form</h2>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="userId">{getUserId()}</label>
                <input id="userId" type="text" value={userId} style={styles.input} readOnly />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="playerName">{getUserName()}</label>
                <input id="playerName" type="text" value={playerName} style={styles.input} readOnly />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="totalBalance">{getBalanceLabel()}</label>
                <input id="totalBalance" type="text" value={totalBalance} style={styles.input} readOnly />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="amount">Amount</label>
                <input
                    id="amount"
                    type="text"
                    placeholder="Amount"
                    style={styles.input}
                    value={amount}
                    onChange={handleAmountChange}
                />
                {amountError && <div style={styles.errorText}>{amountError}</div>}
            </div>
            <div style={{ ...styles.buttonGroup, ...(isDesktop ? styles.buttonGroupRow : {}) }}>
                <CustomButton
                    type="submit"
                    style={{ ...styles.button, ...styles.submitButton, ...(isDesktop ? styles.buttonMarginRight : {}) }}
                    onClick={handleSubmit}
                    disabled={loading} // Disable button when loading
                >
                    {'Submit'}
                </CustomButton>
                <CustomButton type="button" style={{ ...styles.button, ...styles.cancelButton }} onClick={onClose}>
                    Cancel
                </CustomButton>
            </div>
        </div>
    );
};

export default RedeemForm;
