import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import DataTable from "../components/DataTable";
import { useNavigate } from "react-router-dom";
import { Button, styled, IconButton } from "@mui/material";
import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handle403Error } from '../utils/handle403Error';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ScrollToTopButton from '../components/ScrollToTopButton'; // Import the component

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000,
});

const getAuthParams = () => {
    const apiKey = localStorage.getItem("apiKey");
    if (!apiKey) {
        throw new Error("API key is missing");
    }
    return {
        "api-key": apiKey,
    };
};

// Utility function to format date
const formatDateTime = (dateTime) => {
    return dateTime.split(".")[0];
};

const RefreshButton = styled(Button)(({ theme }) => ({
    margin: '8px',
    padding: '8px 15px',
    backgroundColor: '#008951',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#006837',
    },
    [theme.breakpoints.down(650)]: {
        margin: '0px',
        marginBottom: '10px',
        padding: '8px 16px',
        minWidth: 'auto',
        fontSize: '12px',
    },
}));

export default function Game() {
    const [initialRows, setInitialRows] = useState([]);
    const [searchRows, setSearchRows] = useState([]);
    const [formFields, setFormFields] = useState({
        field1: "",
        field2: "",
        field3: "",
        field4: "",
        field5: "",
        field6: "",
        field7: "",
    });
    const [errors, setErrors] = useState({
        field3: "",
        field4: "",
        field6: "",
        field7: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [, setErrorMessage] = useState("");
    const [hasMoreInitial, setHasMoreInitial] = useState(true);
    const [hasMoreSearch, setHasMoreSearch] = useState(false);
    const [page, setPage] = useState(0);
    const [isSearchMode, setIsSearchMode] = useState(false);
    const [isSearchFormVisible, setIsSearchFormVisible] = useState(false);

    const role = localStorage.getItem("role");
    const managerId = localStorage.getItem("userId");
    const navigate = useNavigate();

    const processReceivedData = (data) => {
        return data.map((item) => {
            if (!item.id) {
                item.id = `${item.userId}-${item.gameId}-${item.playedTs}`;
            }
            if (item.playedTs) {
                item.playedTs = formatDateTime(item.playedTs);
            }
            return item;
        });
    };

    const [searchError, setSearchError] = useState(""); // State for search validation error


    const fetchInitialData = useCallback(async () => {
        try {
            setIsLoading(true);
            setErrorMessage("");
            const params = getAuthParams();
            const response = await api.get(`/v1/admin/game-records/initialData`, { params });

            console.log("Initial data response:", response.data);

            if (response.status === 204) {
                setInitialRows([]);
                setHasMoreInitial(false);
            } else {
                const dataWithId = processReceivedData(response.data.data);
                setInitialRows(dataWithId);
                setPage(1); // Start from the first page for infinite scrolling
                setHasMoreInitial(false); // No more data to fetch for initial data
            }
        } catch (error) {
            handle403Error(error, navigate);
        } finally {
            setIsLoading(false);
        }
    }, [navigate]);

    const fetchSearchData = async (resetPage = false) => {
        try {
            setIsLoading(true);
            setErrorMessage("");
            const params = getAuthParams();
            const searchParams = [
                { key: "playerName", value: formFields.field1 },
                { key: "gameName", value: formFields.field2 },
                { key: "balanceBefore", value: formFields.field3 },
                { key: "balanceAfter", value: formFields.field4 },
                { key: "playedOn", value: formFields.field5 },
                { key: "totalBet", value: formFields.field6 },
                { key: "totalWin", value: formFields.field7 },
            ].filter((param) => param.value);

            const currentPage = resetPage ? 0 : page; // Determine the current page
            const response = await api.post(
                `/v1/admin/game-records/search`,
                {
                    page: currentPage,
                    size: 100,
                    searchParams: searchParams,
                },
                { params }
            );

            if (response.status === 204) {
                if (resetPage) {
                    setSearchRows([]);
                }
                setHasMoreSearch(false);
            } else {
                const dataWithId = processReceivedData(response.data.data);
                if (resetPage) {
                    setSearchRows(dataWithId);
                    setPage(1);
                } else {
                    setSearchRows((prevRows) => [...prevRows, ...dataWithId]);
                    setPage((prevPage) => prevPage + 1);
                }
                setHasMoreSearch(response.data.data.length > 0);
            }
        } catch (error) {
            handle403Error(error, navigate);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchInitialData();

        const apiKey = localStorage.getItem("apiKey");
        if (!apiKey) {
            console.error("API key is missing");
            return;
        }

        const socket = new SockJS(`${process.env.REACT_APP_API_BASE_URL}/ws?api-key=${apiKey}`);
        const client = new Client({
            webSocketFactory: () => socket,
            onConnect: () => {
                console.log("WebSocket connected");
                client.subscribe(`/topic/gameRecords/${managerId}`, (response) => {
                    const updatedRecords = JSON.parse(response.body);
                    const processedRecords = processReceivedData(updatedRecords);
                    console.log("Received updated game records: ", processedRecords);
                    setInitialRows((prevRows) => [...prevRows, ...processedRecords]);
                });
            },
            onStompError: (error) => {
                console.error('WebSocket connection error:', error);
            },
        });

        client.activate();

        return () => {
            if (client && client.active) {
                client.deactivate(() => {
                    console.log("WebSocket disconnected");
                });
            }
        };
    }, [managerId, fetchInitialData]);

    const handleReset = async () => {
        setFormFields({
            field1: "",
            field2: "",
            field3: "",
            field4: "",
            field5: "",
            field6: "",
            field7: "",
        });
        setErrors({
            field3: "",
            field4: "",
            field6: "",
            field7: "",
        });
        setSearchError(""); // Clear the error when resetting
        setIsSearchMode(false);
        await fetchInitialData();
    };
    

    const handleSearch = () => {
        const allFieldsEmpty = Object.values(formFields).every(field => !field); // Check if all fields are empty
        
        if (allFieldsEmpty) {
            setSearchError("Please fill in at least one search field before searching."); // Set error if all fields are empty
        } else {
            setSearchError(""); // Clear the error if any field is filled
            setIsSearchMode(true);
            fetchSearchData(true); // Reset page when searching
        }
    };
    

    const handleRefresh = async () => {
        setIsSearchMode(false);
        await fetchInitialData();
    };

    const handleRowClick = () => {
        // Your row click logic here
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let errorMessage = "";
    
        // Check if the input is one of the number-only fields
        if (["field3", "field4", "field6", "field7"].includes(name)) {
            // Check if the value is a valid number (empty value is allowed)
            if (/^\d*\.?\d*$/.test(value)) {
                setFormFields({
                    ...formFields,
                    [name]: value,
                });
                errorMessage = ""; // Clear the error if valid number
            } else {
                errorMessage = "Please enter a valid number"; // Set error message if invalid
            }
        } else {
            // For non-number fields, just update the form fields as usual
            setFormFields({
                ...formFields,
                [name]: value,
            });
        }
    
        // Update the errors for the field
        setErrors({
            ...errors,
            [name]: errorMessage,
        });
    
        // Only clear the search error if there is a valid input
        if (errorMessage === "") {
            setSearchError(""); // Clear the global search error
        }
    };
    
    
    const toggleSearchForm = () => {
        setIsSearchFormVisible(!isSearchFormVisible);
    };

    const columns = [
        {
            field: "userId",
            headerName:
                role === "STORE"
                    ? "Player Id"
                    : role === "SUB_DISTRIBUTOR"
                        ? "Store Id"
                        : role === "DISTRIBUTOR"
                            ? "Sub-Dist Id"
                            : "User Id",
            flex: 1,
            minWidth: 90,
            headerClassName: "custom-header-class",
            cellClassName: "custom-cell-class",
            sortable: false,
        },
        {
            field: "username",
            headerName:
                role === "STORE"
                    ? "Player Name"
                    : role === "SUB_DISTRIBUTOR"
                        ? "Store Name"
                        : role === "DISTRIBUTOR"
                            ? "Sub-Dist Name"
                            : "User Name",
            flex: 1,
            minWidth: 120,
            headerClassName: "custom-header-class",
            cellClassName: "custom-cell-class",
            sortable: false,
        },
        {
            field: "gameName",
            headerName: "Game Name",
            flex: 1,
            minWidth: 130,
            headerClassName: "custom-header-class",
            cellClassName: "custom-cell-class",
            sortable: false,
        },
        {
            field: "balanceBefore",
            headerName: "Balance Before",
            flex: 1,
            minWidth: 130,
            headerClassName: "custom-header-class",
            cellClassName: "custom-cell-class",
            sortable: false,
        },
        {
            field: "balanceAfter",
            headerName: "Balance After",
            flex: 1,
            minWidth: 130,
            headerClassName: "custom-header-class",
            cellClassName: "custom-cell-class",
            sortable: false,
        },
        {
            field: "totalBet",
            headerName: "Total Bet",
            flex: 1,
            minWidth: 100,
            headerClassName: "custom-header-class",
            cellClassName: "custom-cell-class",
            sortable: false,
        },
        {
            field: "totalWin",
            headerName: "Total Win",
            flex: 1,
            minWidth: 100,
            headerClassName: "custom-header-class",
            cellClassName: "custom-cell-class",
            sortable: false,
        },
        {
            field: "playedTs",
            headerName: "Played On",
            flex: 1,
            minWidth: 200,
            headerClassName: "custom-header-class",
            cellClassName: "custom-cell-class",
            sortable: false,
        },
    ];

    const rows = isSearchMode ? searchRows : initialRows;
    const hasMore = isSearchMode ? hasMoreSearch : hasMoreInitial;
    const fetchMoreData = isSearchMode ? fetchSearchData : fetchInitialData;

    return (
        <div className="game">
            <ToastContainer />
            <h1 
                className="h3 mb-3" 
                style={{ marginBottom: "20px", cursor: "pointer" }} 
                onClick={toggleSearchForm}
            >
                <strong>Search</strong>
                <IconButton style={{ marginLeft: '5px', paddingTop: '2px' }}>
                    {isSearchFormVisible ? <CloseIcon /> : <SearchIcon />}
                </IconButton>
            </h1>
            {isSearchFormVisible && (
                <div className="downdiv" style={{ marginBottom: "35px" }}>
                    <form
                        className="searchForm"
                        style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                            gap: "10px",
                        }}
                    >
                        <div>
                            <label htmlFor="field1">Player Name</label>
                            <input
                                type="text"
                                id="field1"
                                name="field1"
                                value={formFields.field1}
                                onChange={handleInputChange}
                                style={{ width: "100%", padding: "8px", margin: "5px 0", border: "1px solid #999" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="field2">Game Name</label>
                            <input
                                type="text"
                                id="field2"
                                name="field2"
                                value={formFields.field2}
                                onChange={handleInputChange}
                                style={{ width: "100%", padding: "8px", margin: "5px 0", border: "1px solid #999" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="field3">Balance Before</label>
                            <input
                                type="text"
                                id="field3"
                                name="field3"
                                value={formFields.field3}
                                onChange={handleInputChange}
                                className={errors.field3 ? "input-error" : ""}
                                style={{ width: "100%", padding: "8px", margin: "5px 0", border: "1px solid #999" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="field4">Balance After</label>
                            <input
                                type="text"
                                id="field4"
                                name="field4"
                                value={formFields.field4}
                                onChange={handleInputChange}
                                className={errors.field4 ? "input-error" : ""}
                                style={{ width: "100%", padding: "8px", margin: "5px 0", border: "1px solid #999" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="field6">Total Bet</label>
                            <input
                                type="text"
                                id="field6"
                                name="field6"
                                value={formFields.field6}
                                onChange={handleInputChange}
                                className={errors.field6 ? "input-error" : ""}
                                style={{ width: "100%", padding: "8px", margin: "5px 0", border: "1px solid #999" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="field7">Total Win</label>
                            <input
                                type="text"
                                id="field7"
                                name="field7"
                                value={formFields.field7}
                                onChange={handleInputChange}
                                className={errors.field7 ? "input-error" : ""}
                                style={{ width: "100%", padding: "8px", margin: "5px 0", border: "1px solid #999" }}
                            />
                        </div>
                        <div>
                            <label htmlFor="field5">Played On</label>
                            <input
                                type="date"
                                id="field5"
                                name="field5"
                                value={formFields.field5}
                                onChange={handleInputChange}
                                style={{ width: "100%", padding: "8px", margin: "5px 0", border: "1px solid #999" }}
                            />
                        </div>
                    </form>
                    {searchError && <p style={{ color: 'red', marginTop: '10px', fontSize: '13px' }}>{searchError}</p>}

                    <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                        <button
                            type="button"
                            onClick={handleSearch}
                            style={{
                                padding: "10px 20px",
                                backgroundColor: "#007BFF",
                                color: "#fff",
                                border: "none",
                                cursor: "pointer",
                            }}
                        >
                            Search
                        </button>
                        <button
                            type="button"
                            onClick={handleReset}
                            className="resetButton"
                            style={{
                                padding: "10px 20px",
                                backgroundColor: "#6c757d",
                                color: "#fff",
                                border: "none",
                                cursor: "pointer",
                            }}
                        >
                            Reset
                        </button>
                    </div>
                </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h1 className="h3"><strong>Game Record</strong></h1>
                </div>
                <RefreshButton style={{ marginRight: 0, paddingRight: '10px' }} onClick={handleRefresh}>
                    Refresh
                </RefreshButton>
            </div>
            <InfiniteScroll
                dataLength={rows.length}
                next={() => fetchMoreData(false)} // Fetch next page
                hasMore={hasMore}
                loader={isSearchMode && <h4>Loading...</h4>}
                endMessage={<p>No more data</p>}
            >
                <DataTable
                    columns={columns}
                    rows={rows}
                    getRowId={(row) => row.id}
                    onRowClick={handleRowClick}
                    isEmpty={rows.length === 0 && !isLoading}
                    isLoading={isLoading}
                />
            </InfiniteScroll>
            <ScrollToTopButton /> {/* Add the scroll to top button */}
        </div>
    );
}
