import React, { useEffect, useState, useContext, createContext, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Dashboard from "./pages/Dashboard";
import UserList from "./pages/UserList";
import Recharge from "./pages/Recharge";
import Redeem from "./pages/Redeem";
import Login from './pages/Login';
import 'react-toastify/dist/ReactToastify.css';
import Gamelist from './pages/Gamelist';
import Profile from './pages/Profile';
import Subdistributor from './pages/Subdistributor';
import Store from './pages/Store';
import Player from './pages/Player';
import Games from './pages/Game';
import Venture from './pages/Ventureresponse';
import Admindashboard from './pages/Admindashboard';
import SlotDetail from './pages/SlotDetail';
import GameOnboarding from './pages/GameOnboarding';
import BroadcastMessage from './pages/BroadcastMessage';
import KenoDetail from './pages/KenoDetail';

// Create an Auth context
const AuthContext = createContext();

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<PrivateRoute />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

function AuthProvider({ children }) {
  const [auth, setAuth] = useState({ token: localStorage.getItem('apiKey'), role: localStorage.getItem('role') });

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
}

function PrivateRoute() {
  const navigate = useNavigate();
  const { auth, setAuth } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  const handleLogout = useCallback(() => {
    localStorage.removeItem("apiKey");
    localStorage.removeItem("role");
    setAuth({});
    window.location.href = "/login";
  }, [setAuth]);

  useEffect(() => {
    const token = localStorage.getItem('apiKey');
    const role = localStorage.getItem('role');
    if (token && role) {
      setAuth({ token, role });
      setLoading(false);
    } else {
      navigate('/login');
    }
  }, [navigate, setAuth]);

  useEffect(() => {
    let logoutTimer;

    const resetTimeout = () => {
      clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        handleLogout();
      }, 1800000); // 30 minutes
    };

    const handleActivity = () => {
      resetTimeout();
    };

    resetTimeout();

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keydown", handleActivity);

    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keydown", handleActivity);
    };
  }, [handleLogout]);

  useEffect(() => {
    const checkApiKey = async () => {
      try {
        const response = await fetch('/api/check-api-key', { headers: { 'Authorization': `Bearer ${auth.token}` } });
        if (response.status === 401) {
          handleLogout();
        } else {
          setLoading(false);
        }
      } catch (error) {
        handleLogout();
      }
    };

    if (auth.token) {
      checkApiKey();
    } else {
      setLoading(false);
    }
  }, [auth.token, handleLogout]);

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a loading spinner or any other loading indicator
  }

  return auth.token ? <MainLayout role={auth.role} /> : <Navigate to="/login" />;
}

function MainLayout({ role }) {
  const [resetUserList, setResetUserList] = useState(false);
  const [resetSlotDetail, setResetSlotDetail] = useState(false);
  const [resetKenoDetail, setResetKenoDetail] = useState(false);
  const [resetGameOnboarding, setResetGameOnboarding] = useState(false);

  const handleUsersListClick = () => {
    setResetUserList(true);
  };

  const handleSlotDetailClick = () => {
    setResetSlotDetail(true);
  };

  const handleKenoDetailClick = () => {
    setResetKenoDetail(true);
  };

  const handleGameOnboardingClick = () => {
    setResetGameOnboarding(true);
  };

  return (
    <div className="wrapper">
      <Sidebar onUsersListClick={handleUsersListClick} onSlotDetailClick={handleSlotDetailClick} onGameOnboardingClick={handleGameOnboardingClick} onKenoDetailClick={handleKenoDetailClick} />
      <div className="main">
        <Navbar />
        <main className="content">
          <div className="container-fluid p-0">
            <Routes>
              {role === 'ADMIN' ? (
                <>
                  <Route path="/admin-dashboard" element={<Admindashboard />} />
                  <Route path="/slot-detail" element={<SlotDetail resetSlotDetail={resetSlotDetail} setResetSlotDetail={setResetSlotDetail} />} />
                  <Route path="/keno-detail" element={<KenoDetail resetSlotDetail={resetKenoDetail} setResetKenoDetail={setResetKenoDetail} />} />
                  <Route path="/game-onboarding" element={<GameOnboarding resetGameOnboarding={resetGameOnboarding} setResetGameOnboarding={setResetGameOnboarding} />} />
                  <Route path="/broadcast-message" element={<BroadcastMessage />} />
                  <Route path="/ventureresponse" element={<Venture />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="*" element={<Navigate to="/admin-dashboard" />} />
                </>
              ) : (
                <>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/user-list" element={<UserList resetUserList={resetUserList} setResetUserList={setResetUserList} />} />
                  <Route path="/recharge" element={<Recharge />} />
                  <Route path="/redeem" element={<Redeem />} />
                  <Route path="/gamelist" element={<Gamelist />} />
                  <Route path="/subdistributor" element={<Subdistributor />} />
                  <Route path="/players" element={<Player />} />
                  <Route path="/games" element={<Games />} />
                  <Route path="/store" element={<Store />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </>
              )}
            </Routes>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default App;
