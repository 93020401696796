import React, { useEffect, useRef, useState, useCallback } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from '../components/DataTable';
import VentureTable from '../components/VentureTable';
import { Button } from "@mui/material";
import { styled } from '@mui/system';
import ScrollToTopButton from '../components/ScrollToTopButton'; // Import the component

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  timeout: 5000,
});

const getAuthParams = () => {
  const apiKey = localStorage.getItem("apiKey");
  if (!apiKey) {
    throw new Error("API key is missing");
  }
  return {
    "api-key": apiKey,
  };
};

const RefreshButton = styled(Button)(({ theme }) => ({
  margin: '8px',
  padding: '8px 15px',
  backgroundColor: '#008951',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#006837',
  },
  [theme.breakpoints.down(650)]: {
    margin: '0px',
    marginBottom: '10px',
    padding: '8px 16px',
    minWidth: 'auto',
    fontSize: '12px',
  },
}));

export default function VentureResponse() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isVentureTableOpen, setIsVentureTableOpen] = useState(false);
  const initialLoadRef = useRef(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showNoMoreData, setShowNoMoreData] = useState(false);

  // This state will determine if the data needs to be fetched again
  const [needsRefresh, setNeedsRefresh] = useState(true);

  const fetchData = useCallback(async () => {
    if (!needsRefresh) return; // Skip the fetch if not needed

    setLoading(true);
    try {
      const params = getAuthParams();
      const response = await api.get("/v1/admin/venture-report/display", {
        params,
      });
      const userData = response.data.data;

      if (!userData || !Array.isArray(userData)) {
        console.error("Fetched user data is invalid or not an array");
        setRows([]);
        return;
      }

      setRows(userData);

      // Reset needsRefresh after data is successfully fetched
      setNeedsRefresh(false);

      if (selectedUser) {
        const updatedSelectedUser = userData.find(user => user.id === selectedUser.id);
        if (updatedSelectedUser) {
          setSelectedUser(updatedSelectedUser);
        } else {
          console.log("Selected user not found in updated data");
        }
      }
    } catch (error) {
      console.error("Error fetching user data: ", error);
    }
    setLoading(false);
    if (initialLoadRef.current) {
      initialLoadRef.current = false;
    }
  }, [selectedUser, needsRefresh]);

  // Only call fetchData on mount and when refresh is explicitly triggered
  useEffect(() => {
    fetchData(); // Only fetch on mount or refresh
  }, [fetchData]);

  const handleRefresh = () => {
    setNeedsRefresh(true); // Set refresh flag to true
  };

  const handleOpenVentureTable = (user) => {
    setSelectedUser(user);
    setIsVentureTableOpen(true);
  };

  const handleCloseVentureTable = () => {
    setIsVentureTableOpen(false);
    // Don't set selectedUser to null here to prevent unnecessary re-renders
  };

  const handleUpdateLastComment = (id, emailResponse) => {
    const updatedId = parseInt(id);

    setRows((prevUsers) => {
      const updatedUsers = prevUsers.map((user) => {
        if (user.id === updatedId) {
          const lastEmailResponseUpdate = new Date().toISOString();
          return { ...user, emailResponse, lastEmailResponseUpdate };
        }
        return user;
      });
      return updatedUsers;
    });

    // Mark table as needing a refresh after an update
    setNeedsRefresh(true);
  };

  const showToastMessage = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "Id",
      flex: 1,
      minWidth: 90,
      headerClassName: "custom-header-class",
      headerAlign: "center",
      cellClassName: "custom-cell-class",
      align: "center",
      sortable: false,
    },
    {
      field: "emailAddress",
      headerName: "Email",
      flex: 1,
      minWidth: 210,
      headerClassName: "custom-header-class",
      headerAlign: "center",
      cellClassName: "custom-cell-class",
      align: "center",
      sortable: false,
    },
    {
      field: "countryAndState",
      headerName: "Location",
      flex: 1,
      minWidth: 150,
      headerClassName: "custom-header-class",
      headerAlign: "center",
      cellClassName: "custom-cell-class",
      align: "center",
      sortable: false,
    },
    {
      field: "investmentRange",
      headerName: "Investment Range",
      flex: 1,
      minWidth: 150,
      headerClassName: "custom-header-class",
      headerAlign: "center",
      cellClassName: "custom-cell-class",
      align: "center",
      sortable: false,
    },
  ];

  // Track the scroll position to determine when to show "No more data"
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight / 2 && !loading && rows.length > 0) { // 50vh condition
        setShowNoMoreData(true);
      } else {
        setShowNoMoreData(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, rows.length]);

  return (
    <div className="ventureResponse">
      <ToastContainer />
      {isVentureTableOpen && selectedUser && (
        <VentureTable
          id={selectedUser.id.toString()}
          emailAddress={selectedUser.emailAddress}
          countryAndState={selectedUser.countryAndState}
          investmentRange={selectedUser.investmentRange}
          myTextarea={selectedUser.myTextarea}
          emailResponse={selectedUser.emailResponse || ""}
          lastEmailResponseUpdate={selectedUser.lastEmailResponseUpdate || ""}
          onUpdate={handleUpdateLastComment}
          onClose={handleCloseVentureTable}
          notifyParent={showToastMessage}
        />
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h1 className="h3"><strong>Venture Records</strong></h1>
        </div>
        <RefreshButton style={{ marginRight: 0, paddingRight: '10px' }} onClick={handleRefresh}>Refresh</RefreshButton>
      </div>
      <DataTable
        columns={columns}
        rows={rows}
        onRowClick={(params) => handleOpenVentureTable(params.row)}
        isEmpty={rows.length === 0}
        isLoading={loading}
      />
      {showNoMoreData && !loading && rows.length === 0 && (
        <p>No more data</p>
      )}
      <ScrollToTopButton />
    </div>
  );
}
