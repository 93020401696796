import {
    Button,
} from '@mui/material';

import { styled } from '@mui/system';

import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { handle403Error } from '../utils/handle403Error'; // Import the shared function

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000,
});

const CustomButton = styled(Button)(({ theme }) => ({
    flex: 1,
    padding: '10px',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    marginBottom: '10px',
    '&:hover': {
        backgroundColor: '#115293',
    },
    [theme.breakpoints.down(650)]: {
        margin: '0px',
        padding: '8px 16px',
        minWidth: 'auto',
        width: '100%',
        fontSize: '12px',
    },
}));

const ResetForm = ({ onClose, userId, playerName }) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state to prevent multiple clicks
    const navigate = useNavigate();

    const role = localStorage.getItem("role");

    const getUserName = () => {
        if (role === "STORE") {
            return "Player Name";
        } else if (role === "SUB_DISTRIBUTOR") {
            return "Store Name";
        } else if (role === "DISTRIBUTOR") {
            return "Sub-Dist Name";
        } else if (role === "MASTER") {
            return "Distributor Name";
        }
    };

    const getUserId = () => {
        if (role === "STORE") {
            return "Player Id";
        } else if (role === "SUB_DISTRIBUTOR") {
            return "Store Id";
        } else if (role === "DISTRIBUTOR") {
            return "Sub-Dist Id";
        } else if (role === "MASTER") {
            return "Distributor Id";
        }
    };

    const handleSuccess = () => {
        toast.success('Password reset successful!', { autoClose: 3000 });
        onClose();
    };

    const getAuthParams = () => {
        const apiKey = localStorage.getItem('apiKey');
        if (!apiKey) {
            throw new Error('API key is missing');
        }
        return {
            'api-key': apiKey,
        };
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (loading) return; // Prevent multiple clicks
        setLoading(true); // Set loading to true to disable the button

        let hasError = false;

        if (!newPassword) {
            setNewPasswordError('New Password is required');
            hasError = true;
        } else {
            setNewPasswordError('');
        }

        if (!confirmPassword) {
            setConfirmPasswordError('Confirm Password is required');
            hasError = true;
        } else {
            setConfirmPasswordError('');
        }

        if (newPassword && confirmPassword && newPassword !== confirmPassword) {
            setPasswordError('Passwords do not match');
            hasError = true;
        } else {
            setPasswordError('');
        }

        if (hasError) {
            setLoading(false); // Reset loading state if validation fails
            return;
        }

        const encodedNewPassword = btoa(newPassword);
        const encodedConfirmPassword = btoa(confirmPassword);

        const payload = {
            username: playerName,
            newPassword: encodedNewPassword,
            confirmPassword: encodedConfirmPassword,
        };

        try {
            const params = getAuthParams();

            const response = await api.post('/v1/admin/user/change-password', payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
                params,
            });

            if (response.status === 200) {
                console.log('Success:', response.data);
                handleSuccess(); // Show success toast and close the form
            } else {
                console.error('Error:', response.data);
                handle403Error(response, navigate); // Show error toast
            }
        } catch (error) {
            console.error('Error:', error);
            handle403Error(error, navigate); // Show error toast
        } finally {
            setLoading(false); // Reset loading state after the API call completes
        }
    };

    const styles = {
        form: {
            width: '300px',
            padding: '20px',
            background: 'white',
            borderRadius: '10px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: 2,
        },
        header: {
            marginBottom: '20px',
            textAlign: 'center',
        },
        inputGroup: {
            marginBottom: '15px',
        },
        label: {
            display: 'block',
            marginBottom: '5px',
            fontWeight: 'bold',
            fontSize: '14px',
        },
        input: {
            width: '100%',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ced4da',
        },
        errorText: {
            color: 'red',
            fontSize: '12px',
        },
        buttonGroup: {
            display: 'flex',
            flexDirection: 'column',
        },
        button: {
            flex: 1,
            padding: '10px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
            marginBottom: '10px',
        },
        submitButton: {
            backgroundColor: '#007bff',
            color: 'white',
            marginRight: '0',
        },
        cancelButton: {
            backgroundColor: '#f44336',
            color: 'white',
            border: '1px solid #ced4da',
        },
        buttonGroupRow: {
            flexDirection: 'row',
        },
        buttonMarginRight: {
            marginRight: '10px',
        },
        passwordWrapper: {
            position: 'relative',
        },
        togglePassword: {
            position: 'absolute',
            top: '50%',
            right: '10px',
            transform: 'translateY(-50%)',
            background: 'none',
            border: 'none',
            cursor: 'pointer',
        },
    };

    const isDesktop = window.innerWidth >= 600;

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
        if (passwordError && e.target.value) {
            setPasswordError('');
        }
        if (newPasswordError && e.target.value) {
            setNewPasswordError('');
        }
        if (confirmPasswordError && e.target.value) {
            setConfirmPasswordError('');
        }
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (passwordError && e.target.value) {
            setPasswordError('');
        }
        if (confirmPasswordError && e.target.value) {
            setConfirmPasswordError('');
        }
        if (!e.target.value) {
            setConfirmPasswordError('');
        } else if (!newPassword) {
            setConfirmPasswordError('Please enter the new password first');
        } else if (!e.target.value) {
            setConfirmPasswordError('');
        }
    };

    const toggleNewPasswordVisibility = () => {
        setNewPasswordVisible(!newPasswordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };

    return (
        <div style={styles.form}>
            <h2 style={styles.header}>Reset Password</h2>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="userId">{getUserId()}</label>
                <input id="userId" type="text" value={userId} style={styles.input} readOnly />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="playerName">{getUserName()}</label>
                <input id="playerName" type="text" value={playerName} style={styles.input} readOnly />
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="newPassword">New Password</label>
                <div style={styles.passwordWrapper}>
                    <input
                        id="newPassword"
                        type={newPasswordVisible ? 'text' : 'password'}
                        placeholder="New Password"
                        style={styles.input}
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                    />
                    <button
                        type="button"
                        style={styles.togglePassword}
                        onClick={toggleNewPasswordVisibility}
                        aria-label="Toggle password visibility"
                    >
                        <FontAwesomeIcon icon={newPasswordVisible ? faEyeSlash : faEye} />
                    </button>
                </div>
                {newPasswordError && <div style={styles.errorText}>{newPasswordError}</div>}
            </div>
            <div style={styles.inputGroup}>
                <label style={styles.label} htmlFor="confirmPassword">Confirm Password</label>
                <div style={styles.passwordWrapper}>
                    <input
                        id="confirmPassword"
                        type={confirmPasswordVisible ? 'text' : 'password'}
                        placeholder="Confirm Password"
                        style={styles.input}
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                    />
                    <button
                        type="button"
                        style={styles.togglePassword}
                        onClick={toggleConfirmPasswordVisibility}
                        aria-label="Toggle confirm password visibility"
                    >
                        <FontAwesomeIcon icon={confirmPasswordVisible ? faEyeSlash : faEye} />
                    </button>
                </div>
                {confirmPasswordError && <div style={styles.errorText}>{confirmPasswordError}</div>}
                {passwordError && <div style={styles.errorText}>{passwordError}</div>}
            </div>
            <div style={{ ...styles.buttonGroup, ...(isDesktop ? styles.buttonGroupRow : {}) }}>
                <CustomButton
                    type="submit"
                    style={{ ...styles.button, ...styles.submitButton, ...(isDesktop ? styles.buttonMarginRight : {}) }}
                    onClick={handleSubmit}
                    disabled={loading} // Disable button when loading
                >
                    {'Submit'}
                </CustomButton>
                <CustomButton type="button" style={{ ...styles.button, ...styles.cancelButton }} onClick={onClose}>
                    Cancel
                </CustomButton>
            </div>
        </div>
    );
};

export default ResetForm;
