import { useEffect, useRef, useState, useCallback } from "react";
import DataTable from '../components/DataTable';
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OnboardGame from "../components/OnboardGame";
import UpdateGame from "../components/UpdateGame";
import { Modal, Box, Button, styled, Backdrop } from '@mui/material';
import { handle403Error } from '../utils/handle403Error'; // Import the utility function
import { useNavigate } from 'react-router-dom';
import ScrollToTopButton from '../components/ScrollToTopButton'; // Import the component

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000,
});

const RefreshButton = styled(Button)(({ theme }) => ({
    margin: '8px',
    padding: '8px 15px',
    backgroundColor: '#008951',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#006837',
    },
    [theme.breakpoints.down(650)]: {
        margin: '0px',
        padding: '8px 16px',
        minWidth: 'auto',
        fontSize: '12px',
    },
}));

const OnboardButton = styled(Button)(({ theme }) => ({
    margin: '8px',
    padding: '8px 15px',
    backgroundColor: '#2196f3',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#115293',
    },
    [theme.breakpoints.down(650)]: {
        margin: '0px',
        padding: '8px 16px',
        minWidth: 'auto',
        fontSize: '12px',
    },
}));

export default function GameOnboarding({ resetGameOnboarding, setResetGameOnboarding }) {
    const [rows, setRows] = useState([]);
    const [selectedGame, setSelectedGame] = useState(null);
    const initialLoadRef = useRef(true);
    const [openOnboard, setOpenOnboard] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const getAuthParams = () => {
        const apiKey = localStorage.getItem("apiKey");
        if (!apiKey) {
            throw new Error("API key is missing");
        }
        return {
            "api-key": apiKey,
        };
    };

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const params = getAuthParams();

            const response = await api.get("/v2/admin/game-info/all-games", {
                params,
            });
            const data = response.data.data.map((item) => ({
                slotId: item.slotId,
                slotStatus: item.slotStatus,
                gameId: item.gameId,
                gameName: item.gameName,
                gameCode: item.gameCode,
                status: item.status,
                parentId: item.parentId,
                gameType: item.gameType,
                gameSubType: item.gameSubType,
                id: item.gameId, // Ensure id is included
            }));
            setRows(data);
        } catch (error) {
            handle403Error(error, navigate);
        }
        if (initialLoadRef.current) {
            initialLoadRef.current = false;
        }
        setIsLoading(false);
    }, [navigate]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (resetGameOnboarding) {
            setSelectedGame(null);
            setResetGameOnboarding(false);
            fetchData(); // Fetch data again when the "Game Onboarding" link is clicked
        }
    }, [resetGameOnboarding, setResetGameOnboarding, fetchData]);

    const handleRefresh = () => {
        fetchData();
    };

    const handleRowClick = async (params) => {
        try {
            const authParams = getAuthParams();

            const response = await api.get(`/v2/admin/game-info/${params.id}`, {
                params: authParams,
            });
            setSelectedGame(response.data.data);

            // Scroll to the top of the page when a row is clicked
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } catch (error) {
            handle403Error(error, navigate);
        }
    };

    const handleAddGame = async (newGame) => {
        try {
            setRows((prevGames) => [...prevGames, newGame]);
        } catch (error) {
            console.error("Error during game creation:", error);
        }
    };

    const handleUpdateGame = (updatedGame) => {
        setRows((prevGames) =>
            prevGames.map((game) =>
                game.gameId === updatedGame.gameId ? updatedGame : game
            )
        );
        setSelectedGame(updatedGame); // Update the selected game
    };

    const handleCloseDetails = () => {
        setSelectedGame(null);
    };

    const columns = [
        { field: 'gameId', headerName: 'ID', flex: 1, minWidth: 300 },
        { field: 'gameName', headerName: 'Game Name', flex: 1, minWidth: 150 },
        { field: 'gameCode', headerName: 'Game Code', flex: 1, minWidth: 150 },
        { field: 'status', headerName: 'Status', flex: 1, minWidth: 150 },
        { field: 'parentId', headerName: 'Parent Id', flex: 1, minWidth: 300 },
        { field: 'gameType', headerName: 'Game Type', flex: 1, minWidth: 150 },
        { field: 'gameSubType', headerName: 'Game Sub Type', flex: 1, minWidth: 150 },
    ];

    return (
        <div className="gameOnboarding">
            <ToastContainer />
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <h1 className="h3"><strong>Games</strong></h1>
            </div>
            <div className="userRefresh" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
                <div className="texts" style={{ display: 'flex', alignItems: 'center' }}>
                    <OnboardButton style={{ marginLeft: 0 }} onClick={() => setOpenOnboard(true)}>
                        Onboard Games
                    </OnboardButton>
                </div>
                <RefreshButton style={{ marginRight: 0 }} onClick={handleRefresh}>
                    Refresh
                </RefreshButton>
            </div>

            {selectedGame && (
                <div className="gameDetails" style={{ marginTop: '20px', padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', marginBottom: '20px', }}>
                    <div className="slotMainDiv" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                        <h3 style={{ margin: 0 }}>Game Details</h3>
                        <button
                            className="closeButton"
                            onClick={handleCloseDetails}
                            style={{ backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '20%', cursor: 'pointer', padding: '2px', width: '25px' }}
                        >
                            X
                        </button>
                    </div>
                    <button
                        className="updateButton"
                        onClick={() => setOpenUpdate(true)}
                        style={{ backgroundColor: '#d6d7dd', marginBottom: '10px', color: '#333333', border: 'none', padding: '7px 20px', fontSize: '16px', fontWeight: '600', cursor: 'pointer', borderRadius: '5px', transition: 'background-color 0.3s, color 0.3s, border-color 0.3s' }}
                    >
                        Update
                    </button>
                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <tbody>
                            <tr>
                                <td style={{ padding: '8px 12px', border: '1px solid #ddd', textAlign: 'left' }}>ID</td>
                                <td style={{ padding: '8px 12px', border: '1px solid #ddd', textAlign: 'left' }}>{selectedGame.gameId}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '8px 12px', border: '1px solid #ddd', textAlign: 'left' }}>Name</td>
                                <td style={{ padding: '8px 12px', border: '1px solid #ddd', textAlign: 'left' }}>{selectedGame.gameName}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '8px 12px', border: '1px solid #ddd', textAlign: 'left' }}>Code</td>
                                <td style={{ padding: '8px 12px', border: '1px solid #ddd', textAlign: 'left' }}>{selectedGame.gameCode}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '8px 12px', border: '1px solid #ddd', textAlign: 'left' }}>Status</td>
                                <td style={{ padding: '8px 12px', border: '1px solid #ddd', textAlign: 'left' }}>{selectedGame.status}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '8px 12px', border: '1px solid #ddd', textAlign: 'left' }}>Parent ID</td>
                                <td style={{ padding: '8px 12px', border: '1px solid #ddd', textAlign: 'left' }}>{selectedGame.parentId}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '8px 12px', border: '1px solid #ddd', textAlign: 'left' }}>Type</td>
                                <td style={{ padding: '8px 12px', border: '1px solid #ddd', textAlign: 'left' }}>{selectedGame.gameType}</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '8px 12px', border: '1px solid #ddd', textAlign: 'left' }}>Sub-Type</td>
                                <td style={{ padding: '8px 12px', border: '1px solid #ddd', textAlign: 'left' }}>{selectedGame.gameSubType}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
            <DataTable
                columns={columns}
                rows={rows}
                onRowClick={handleRowClick}
                getRowId={(row) => row.id} // Ensure each row has a unique id
                isEmpty={rows.length === 0}
                isLoading={isLoading}
            />

            <Modal
                open={openOnboard}
                onClose={() => setOpenOnboard(false)}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    style: { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
                }}
            >
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <OnboardGame
                        setOpen={setOpenOnboard}
                        onAddGame={handleAddGame}
                    />
                </Box>
            </Modal>

            <Modal
                open={openUpdate}
                onClose={() => setOpenUpdate(false)}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    style: { backgroundColor: 'rgba(0, 0, 0, 0.7)' },
                }}
            >
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    {selectedGame && (
                        <UpdateGame
                            setOpen={setOpenUpdate}
                            selectedGame={selectedGame}
                            onUpdateGame={handleUpdateGame}
                        />
                    )}
                </Box>
            </Modal>
            <ScrollToTopButton />

        </div>
    );
}
