import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
    const [balanceMap, setBalanceMap] = useState({});
    const [client, setClient] = useState(null);

    const connectWebSocket = useCallback(() => {
        try {
            const apiKey = localStorage.getItem("apiKey");
            if (!apiKey) {
                console.error("API key is missing");
                return;
            }

            const socket = new SockJS(`${process.env.REACT_APP_API_BASE_URL}/ws?api-key=${apiKey}`);
            const newClient = new Client({
                webSocketFactory: () => socket,
                onConnect: () => {
                    console.log("WebSocket connected");
                    newClient.subscribe('/topic/balance', (response) => {
                        const updatedBalance = JSON.parse(response.body);
                        console.log("Received balance update:", updatedBalance);
                        setBalanceMap(prevMap => ({
                            ...prevMap,
                            ...updatedBalance
                        }));
                    });
                },
                onStompError: (error) => {
                    console.error('WebSocket connection error:', error);
                },
            });

            newClient.onWebSocketError = (error) => {
                console.error('WebSocket error:', error);
            };

            newClient.activate();
            setClient(newClient);
        } catch (error) {
            console.error('Error during WebSocket connection:', error);
        }
    }, []);

    useEffect(() => {
        return () => {
            if (client && client.active) {
                client.deactivate(() => {
                    console.log("WebSocket disconnected");
                });
            }
        };
    }, [client]);

    return (
        <WebSocketContext.Provider value={{ balanceMap, connectWebSocket }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => useContext(WebSocketContext);

export { WebSocketContext };
export default WebSocketProvider;
