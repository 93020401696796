import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { handle403Error } from '../utils/handle403Error';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 5000,
});

// Fetch active slots
const fetchActiveSlots = async () => {
    try {
        const params = {
            "api-key": localStorage.getItem("apiKey"),
        };
        const response = await api.get("/v2/slot/admin/get-all-slots-info", { params });
        return response.data.data
            .filter((item) => item.slotStatus === "ACTIVE")
            .map((item) => ({
                slotId: item.slotId,
                slotName: item.slotName,
                slotType: item.slotType,
            }));
    } catch (error) {
        console.error("Error fetching active slots:", error);
        toast.error("Error fetching active slots.");
        return [];
    }
};

// Fetch active Keno games
const fetchActiveKenoGames = async () => {
    try {
        const params = {
            "api-key": localStorage.getItem("apiKey"),
        };
        const response = await api.get("/v2/keno/admin/get-all-keno-info", { params });
        const kenoData = response.data.data;
        const kenoArray = Array.isArray(kenoData) ? kenoData : [kenoData];
        return kenoArray
            .filter((keno) => keno.kenoStatus === "ACTIVE")
            .map((keno) => ({
                kenoId: keno.kenoId,
                kenoName: keno.kenoName,
            }));
    } catch (error) {
        console.error("Error fetching active Keno games:", error);
        toast.error("Error fetching active Keno games.");
        return [];
    }
};

const UpdateGame = ({ setOpen, selectedGame, onUpdateGame }) => {
    const [gameId, setGameId] = useState("");
    const [gameName, setGameName] = useState("");
    const [gameCode, setGameCode] = useState("");
    const [status, setStatus] = useState(""); // Track status with dropdown
    const [parentId, setParentId] = useState(""); 
    const [gameSubType, setGameSubType] = useState("");
    const [activeSlots, setActiveSlots] = useState([]);
    const [activeKenoGames, setActiveKenoGames] = useState([]);
    const [newParentId, setNewParentId] = useState("");
    const [currentParentName, setCurrentParentName] = useState("");
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const loadActiveGames = async () => {
            const slots = await fetchActiveSlots();
            const kenoGames = await fetchActiveKenoGames();
            setActiveSlots(slots);
            setActiveKenoGames(kenoGames);

            if (selectedGame) {
                setGameId(selectedGame.gameId);
                setGameName(selectedGame.gameName);
                setGameCode(selectedGame.gameCode);
                setStatus(selectedGame.status); // Set current status
                setParentId(selectedGame.parentId);  
                setNewParentId("");  
                setGameSubType("");  

                if (selectedGame.gameType === "SLOT_MACHINE") {
                    const parentSlot = slots.find((slot) => slot.slotId === selectedGame.parentId);
                    setCurrentParentName(parentSlot?.slotName || "");
                } else if (selectedGame.gameType === "KENO") {
                    const parentKeno = kenoGames.find((keno) => keno.kenoId === selectedGame.parentId);
                    setCurrentParentName(parentKeno?.kenoName || "");
                }
            }
        };
        loadActiveGames();
    }, [selectedGame]);

    const validateForm = () => {
        let newErrors = {};
        if (!gameName) newErrors.gameName = "Game Name is required";
        if (!gameCode) newErrors.gameCode = "Game Code is required";
        if (!status) newErrors.status = "Status is required";
        if (selectedGame.gameType === "SLOT_MACHINE" && !gameSubType) newErrors.gameSubType = "Game Sub-Type is required";
        if (!newParentId) newErrors.newParentId = selectedGame.gameType === "SLOT_MACHINE" ? "Slot Name is required" : "Keno Name is required";
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        const apiKey = localStorage.getItem("apiKey");

        if (!apiKey) {
            toast.error("Authentication details not found.");
            return;
        }

        try {
            const payload = {
                gameId,
                gameName,
                gameCode,
                status, // Send updated status
                parentId: newParentId, 
                gameType: selectedGame.gameType,
                gameSubType: selectedGame.gameType === "SLOT_MACHINE" ? gameSubType : "NORMAL",  
            };

            const response = await api.put(
                `/v2/admin/game-info/update-game`,
                payload,
                {
                    params: { "api-key": apiKey },
                }
            );

            const responseData = response.data;

            if (responseData.success) {
                const updatedGame = { ...responseData.data, id: responseData.data.gameId };
                onUpdateGame(updatedGame);
                toast.success(responseData.message);
                setOpen(false);
            } else {
                toast.error(responseData.message || responseData.error);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                const { message, error: errorMessage } = error.response.data;
                toast.error(message || errorMessage || "Error updating game.");
            } else {
                handle403Error(error, navigate);
            }
        }
    };

    const handleChange = (field, value) => {
        switch (field) {
            case "gameName":
                setGameName(value);
                break;
            case "gameCode":
                setGameCode(value);
                break;
            case "status":
                setStatus(value); // Update status field
                break;
            case "newParentId":
                setNewParentId(value);
                if (selectedGame.gameType === "SLOT_MACHINE") {
                    const selectedSlot = activeSlots.find(slot => slot.slotId === value);
                    setGameSubType(selectedSlot?.slotType || "");
                }
                break;
            default:
                break;
        }
        setErrors((prev) => ({ ...prev, [field]: "" }));
    };

    const styles = {
        form: {
            width: '100%',
            maxWidth: '600px',
            padding: '20px',
            background: 'white',
            borderRadius: '10px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: 2,
            margin: 'auto',
        },
        header: {
            marginBottom: '20px',
            textAlign: 'center',
        },
        inputGroup: {
            marginBottom: '15px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        label: {
            fontWeight: 'bold',
            fontSize: '14px',
            flex: '1',
        },
        inputErrorGroup: {
            display: 'flex',
            flexDirection: 'column',
            flex: '2',
        },
        input: {
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ced4da',
            width: '100%',
        },
        readOnlyInput: {
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ced4da',
            backgroundColor: '#f9f9f9',
            color: '#6c757d',
            cursor: 'not-allowed',
            width: '100%',
        },
        error: {
            color: 'red',
            fontSize: '12px',
            marginTop: '5px',
            textAlign: 'left',
        },
        buttonGroup: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: '20px',
        },
        button: {
            padding: '10px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
            marginBottom: '10px',
            marginRight: '10px',
            flex: '1',
        },
        submitButton: {
            backgroundColor: '#007bff',
            color: 'white',
        },
        cancelButton: {
            backgroundColor: '#f44336',
            color: 'white',
            border: '1px solid #ced4da',
        },
    };

    return (
        <div style={styles.form}>
            <h2 style={styles.header}>Update Game</h2>
            <form className="mainForm" onSubmit={handleSubmit}>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>Game Name</label>
                    <div style={styles.inputErrorGroup}>
                        <input
                            type="text"
                            placeholder="Enter a Game Name"
                            value={gameName}
                            onChange={(e) => handleChange("gameName", e.target.value)}
                            style={styles.input}
                        />
                        {errors.gameName && <div style={styles.error}>{errors.gameName}</div>}
                    </div>
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>Game Code</label>
                    <div style={styles.inputErrorGroup}>
                        <input
                            type="text"
                            placeholder="Enter a Game Code"
                            value={gameCode}
                            onChange={(e) => handleChange("gameCode", e.target.value)}
                            style={styles.input}
                        />
                        {errors.gameCode && <div style={styles.error}>{errors.gameCode}</div>}
                    </div>
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>Status</label>
                    <div style={styles.inputErrorGroup}>
                        <select
                            value={status}
                            onChange={(e) => handleChange("status", e.target.value)}
                            style={styles.input}
                        >
                            <option value="ACTIVE">ACTIVE</option>
                            <option value="INACTIVE">INACTIVE</option>
                        </select>
                        {errors.status && <div style={styles.error}>{errors.status}</div>}
                    </div>
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>Game Type</label>
                    <div style={styles.inputErrorGroup}>
                        <input
                            type="text"
                            value={selectedGame.gameType}
                            readOnly
                            style={styles.readOnlyInput}
                        />
                    </div>
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>
                        {selectedGame.gameType === "SLOT_MACHINE" ? "Current Slot Name" : "Current Keno Name"}
                    </label>
                    <div style={styles.inputErrorGroup}>
                        <input
                            type="text"
                            value={currentParentName}
                            readOnly
                            style={styles.readOnlyInput}
                        />
                    </div>
                </div>
                <div style={styles.inputGroup}>
                    <label style={styles.label}>Current Parent ID</label>
                    <div style={styles.inputErrorGroup}>
                        <input
                            type="text"
                            value={parentId}
                            readOnly
                            style={styles.readOnlyInput}
                        />
                    </div>
                </div>
                {/* Slot and Keno handling */}
                {selectedGame.gameType === "SLOT_MACHINE" && (
                    <>
                        <div style={styles.inputGroup}>
                            <label style={styles.label}>New Slot Name</label>
                            <div style={styles.inputErrorGroup}>
                                <select
                                    value={newParentId}
                                    onChange={(e) => handleChange("newParentId", e.target.value)}
                                    style={styles.input}
                                >
                                    <option value="">Select a new Slot Name</option>
                                    {activeSlots.map((slot) => (
                                        <option key={slot.slotId} value={slot.slotId}>
                                            {slot.slotName}
                                        </option>
                                    ))}
                                </select>
                                {errors.newParentId && <div style={styles.error}>{errors.newParentId}</div>}
                            </div>
                        </div>
                        <div style={styles.inputGroup}>
                            <label style={styles.label}>New Parent ID</label>
                            <div style={styles.inputErrorGroup}>
                                <input
                                    type="text"
                                    value={newParentId || "Select a new Slot Name to display ID"}
                                    readOnly
                                    style={styles.readOnlyInput}
                                />
                            </div>
                        </div>
                        <div style={styles.inputGroup}>
                            <label style={styles.label}>Slot Sub-Type</label>
                            <div style={styles.inputErrorGroup}>
                                <input
                                    type="text"
                                    value={gameSubType}
                                    readOnly
                                    placeholder="Select a Slot to view Sub-Type"
                                    style={styles.readOnlyInput}
                                />
                            </div>
                        </div>
                    </>
                )}

                {selectedGame.gameType === "KENO" && (
                    <>
                        <div style={styles.inputGroup}>
                            <label style={styles.label}>New Keno Name</label>
                            <div style={styles.inputErrorGroup}>
                                <select
                                    value={newParentId}
                                    onChange={(e) => handleChange("newParentId", e.target.value)}
                                    style={styles.input}
                                >
                                    <option value="">Select a new Keno Name</option>
                                    {activeKenoGames.map((keno) => (
                                        <option key={keno.kenoId} value={keno.kenoId}>
                                            {keno.kenoName}
                                        </option>
                                    ))}
                                </select>
                                {errors.newParentId && <div style={styles.error}>{errors.newParentId}</div>}
                            </div>
                        </div>
                        <div style={styles.inputGroup}>
                            <label style={styles.label}>New Parent ID</label>
                            <div style={styles.inputErrorGroup}>
                                <input
                                    type="text"
                                    value={newParentId || "Select a new Keno Name to display ID"}
                                    readOnly
                                    style={styles.readOnlyInput}
                                />
                            </div>
                        </div>
                    </>
                )}

                <div style={styles.buttonGroup}>
                    <button type="submit" style={{ ...styles.button, ...styles.submitButton }}>
                        Update
                    </button>
                    <button type="button" style={{ ...styles.button, ...styles.cancelButton }} onClick={() => setOpen(false)}>
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};

export default UpdateGame;
