import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import forge from "node-forge";
import { WebSocketContext } from '../WebSocketContext';
import ReCAPTCHA from 'react-google-recaptcha';

// Read and format the public key from the environment variable
const publicKeyPem = process.env.REACT_APP_PUBLIC_KEY.replace(/\\n/g, '\n');
const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const encrypt = (publicKeyPem, message) => {
    try {
        const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
        const encrypted = publicKey.encrypt(message, 'RSA-OAEP', {
            md: forge.md.sha256.create(),
        });
        return forge.util.encode64(encrypted);
    } catch (error) {
        console.error("Error encrypting the string:", error.message);
        return null;
    }
};

const styles = {
    passwordWrapper: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center'
    },
    passwordInput: {
        paddingRight: '2.5rem'
    },
    togglePassword: {
        position: 'absolute',
        right: '0.75rem',
        cursor: 'pointer',
        background: 'none',
        border: 'none'
    },
    errorText: {
        color: 'red',
        fontSize: '0.875rem'
    },
    recaptchaWrapper: {
        display: 'flex',
        justifyContent: 'center',
        margin: '0 auto',  // Center the reCAPTCHA horizontally
    },
};

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [error, setError] = useState("");
    const { connectWebSocket } = useContext(WebSocketContext);
    const enableRecaptcha = process.env.REACT_APP_ENABLE_RECAPTCHA === 'true';

    console.log("RECAPTCHA ENABLED:", enableRecaptcha);

    const togglePasswordVisibility = () => {
        setPasswordVisibility(!passwordVisibility);
    };

    useEffect(() => {
        const storedPlayerId = localStorage.getItem("username");
        const storedPassword = localStorage.getItem("password");

        if (storedPlayerId && storedPassword) {
            setUsername(storedPlayerId);
            setPassword(storedPassword);
        }

        if (location.state && location.state.errorMessage && !location.state.notificationShown) {
            toast.error(location.state.errorMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                closeButton: true,
                className: 'toast-notification',
                progressClassName: 'toast-progress'
            });
            navigate(location.pathname, { replace: true, state: { notificationShown: true } });
        }
    }, [location, navigate]);

    const validateForm = () => {
        if (!username) {
            setError("Username is required");
            return false;
        }

        if (!password) {
            setError("Password is required");
            return false;
        }

        if (enableRecaptcha && !recaptchaValue) {
            toast.error("Please complete the reCAPTCHA verification.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                closeButton: true,
                className: 'toast-notification',
                progressClassName: 'toast-progress'
            });
            return false;
        }

        setError("");
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            const encryptedPassword = encrypt(publicKeyPem, password);

            console.log("Encrypted Password:", encryptedPassword);

            const backendResponse = await axios.post(
                `${process.env.REACT_APP_API_BASE_URL}/v2/user/login`,
                {
                    username,
                    password: encryptedPassword,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            const { apiKey, role, userId, message, error } = backendResponse.data;

            if (apiKey) {
                localStorage.setItem("apiKey", apiKey);
                localStorage.setItem("role", role);
                localStorage.setItem("userId", userId);
                localStorage.setItem("username", username);

                connectWebSocket(); // Connect WebSocket after successful login

                const allowedRoles = ["ADMIN", "MASTER", "DISTRIBUTOR", "SUB_DISTRIBUTOR", "STORE"];
                if (allowedRoles.includes(role)) {
                    switch (role) {
                        case "ADMIN":
                            document.title = "Admin-Dashboard-Diamond-Casino";
                            navigate("/admin-dashboard");
                            break;
                        case "STORE":
                            document.title = "Store-Diamond-Casino";
                            navigate("/");
                            break;
                        case "SUB_DISTRIBUTOR":
                            document.title = "Sub-Dist-Diamond-Casino";
                            navigate("/");
                            break;
                        case "DISTRIBUTOR":
                            document.title = "Distributor-Diamond-Casino";
                            navigate("/");
                            break;
                        case "MASTER":
                            document.title = "Master-Diamond-Casino";
                            navigate("/");
                            break;
                        default:
                            console.warn(`Unexpected role: ${role}`);
                            break;
                    }
                } else {
                    toast.error("Not authorized", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        closeButton: true,
                        className: 'toast-notification',
                        progressClassName: 'toast-progress'
                    });
                }
            } else {
                toast.error(message || "Login failed. Please check with your admin.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    closeButton: true,
                    className: 'toast-notification',
                    progressClassName: 'toast-progress'
                });
                console.error("Login failed", error);
            }

        } catch (error) {
            console.error("Login failed", error);

            let errorMessage = "Login failed. Please try again.";
            if (axios.isAxiosError(error)) {
                if (error.response && error.response.data) {
                    if (typeof error.response.data === "string") {
                        errorMessage = error.response.data;
                    } else if (error.response.data.error) {
                        errorMessage = error.response.data.error;
                    } else if (error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else if (
                        Array.isArray(error.response.data.errors) &&
                        error.response.data.errors.length > 0
                    ) {
                        errorMessage = error.response.data.errors
                            .map((e) => e.message)
                            .join("; ");
                    }
                }
            }

            toast.error(errorMessage, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                closeButton: true,
                className: 'toast-notification',
                progressClassName: 'toast-progress'
            });
        }
    };

    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
        if (error.includes("reCAPTCHA")) {
            setError("");
        }
    };

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
        if (error.includes("Username")) {
            setError("");
        }
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (error.includes("Password")) {
            setError("");
        }
    };

    return (
        <main className="d-flex w-100" style={{ height: '100vh' }}>
            <div className="container d-flex flex-column">
                <div className="row vh-100">
                    <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
                        <div className="d-table-cell align-middle">
                            <div className="text-center mt-4">
                                <h1 className="h2">Welcome back!</h1>
                                <p className="lead">Sign in to your account to continue</p>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="m-sm-3">
                                        <form onSubmit={handleSubmit}>
                                            <div className="mb-3">
                                                <label className="form-label">Username</label>
                                                <input
                                                    className="form-control form-control-lg"
                                                    type="text"
                                                    name="username"
                                                    placeholder="Enter your username"
                                                    value={username}
                                                    onChange={handleUsernameChange}
                                                />
                                                {error && error.includes("Username") && <p style={styles.errorText}>{error}</p>}
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Password</label>
                                                <div style={styles.passwordWrapper}>
                                                    <input
                                                        className="form-control form-control-lg"
                                                        type={passwordVisibility ? "text" : "password"}
                                                        name="password"
                                                        placeholder="Enter your password"
                                                        value={password}
                                                        onChange={handlePasswordChange}
                                                        style={styles.passwordInput}
                                                    />
                                                    <button
                                                        type="button"
                                                        style={styles.togglePassword}
                                                        onClick={togglePasswordVisibility}
                                                        aria-label="Toggle password visibility"
                                                    >
                                                        <FontAwesomeIcon icon={passwordVisibility ? faEyeSlash : faEye} />
                                                    </button>
                                                </div>
                                                {error && error.includes("Password") && <p style={styles.errorText}>{error}</p>}
                                            </div>
                                            {enableRecaptcha && (
                                                <div style={styles.recaptchaWrapper}>
                                                    <ReCAPTCHA
                                                        sitekey={recaptchaSiteKey}
                                                        onChange={handleRecaptchaChange}
                                                    />
                                                </div>
                                            )}
                                            <div className="d-grid gap-2 mt-3">
                                                <button className="btn btn-lg btn-primary" type="submit">Sign in</button>
                                            </div>
                                        </form>
                                        <ToastContainer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Login;
